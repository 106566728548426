<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-22 13:38:49
 * @ Description: Fixer's professional service request page in the user's professional service dashboard section of the app.
 -->

<template>
    <v-row
        justify="center"
    >
        <!-- Desktop -->
        <v-col
            cols="12"
            v-if="$vuetify.breakpoint.mdAndUp"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
        >
            <v-row>
                <!-- Back Button & Heading -->
                <v-col
                    cols="12"
                    class="os-22-sb"
                >
                    <v-btn
                        icon
                        @click="$router.push('/service/search')"
                        class="mb-1"
                    >
                        <v-icon
                            size="24"
                            color="#2E567A"
                            style="opacity: 0.65;"
                        >
                            {{ mdiArrowLeftThinCircleOutline }}
                        </v-icon>
                    </v-btn>
                    <span class="os-22-r">{{ serviceToRequestDetail.name }}</span>
                </v-col>
                <v-col
                    cols="12"
                    class="py-0"
                >
                    <v-divider />
                </v-col>
                <!-- Back Button & Heading -->

                <!-- Request -->
                <v-col
                    cols="6"
                    style="max-height: calc(100vh - 174px); overflow-y: auto;"
                >
                    <v-form
                        v-model="validServiceRequestForm"
                        ref="serviceRequestForm"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="os-15-sb pb-0"
                            >
                                Service Requirements
                            </v-col>

                            <!-- Requirements -->
                            <v-col>
                                <general-tip-tap-text-editor-component
                                    class="pb-3"
                                    :placeholder="'Provide some information about the service that you require. Be as descriptive as possible.'"
                                    @textChanged="descriptionChanged"
                                    :text="serviceRequest.requirements"
                                />
                            </v-col>
                            <!-- Requirements -->

                            <!-- Documents -->
                            <v-col
                                cols="12"
                            >
                                <general-document-component
                                    :documents="serviceRequestDocuments"
                                    :documentCheckboxes="false"
                                    :canDeleteDocuments="true"
                                    :canDownloadDocuments="false"
                                    :canEditDocuments="true"
                                    :fileType="'files'"
                                    @deleteDocument="confirmDeleteDocument"
                                    @createDocument="createDocument"
                                />
                            </v-col>
                            <!-- Documents -->

                            <!-- Date Requirements -->
                            <v-col
                                class="os-15-sb pb-0"
                                cols="12"
                            >
                                Do you require this by a specific date?
                            </v-col>
                            <v-col
                                md="12"
                                lg="9"
                            >
                                <v-row
                                    align="center"
                                >
                                    <v-col
                                        class="pr-1 flex-shrink-0 flex-grow-1"
                                    >
                                        <v-radio-group
                                            row
                                            v-model="serviceRequest.is_specific_date"
                                            hide-details
                                            style="margin-top: 0px;"
                                            @change="specificDateChanged"
                                        >
                                            <v-radio
                                                label="Yes"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="Yes"
                                            />
                                            <v-radio
                                                label="No"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="No"
                                            />
                                            <v-radio
                                                label="Unknown"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="Unknown"
                                            />
                                        </v-radio-group>
                                    </v-col>
                                    <v-col
                                        class="pl-0 flex-shrink-1 flex-grow-0"
                                    >
                                        <v-menu
                                            v-model="dateMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    :rules="dateRules"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-model="serviceRequest.start_date"
                                                    style="width: 150px;"
                                                    :disabled="serviceRequest.is_specific_date !== 'Yes'"
                                                />
                                            </template>
                                            <v-date-picker
                                                no-title
                                                scrollable
                                                color="#2E567A"
                                                @input="dateMenu = false"
                                                v-model="serviceRequest.start_date"
                                            />
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- Date Requirements -->

                            <!-- E-mail Notifications -->
                            <!-- <v-col
                                md="12"
                                lg="9"
                                class="os-12-r"
                                style="color: #8F9BB3;"
                            >
                                Your request will be sent to the fixer and they will be notified of via the messenger. Do you want to be <span class="os-12-sb">notified via email</span> when you receive a reply?
                            </v-col> -->
                            <v-col
                                md="12"
                                lg="9"
                            >
                                <v-row
                                    align="center"
                                >
                                    <!-- <v-col
                                        cols="12"
                                    >
                                        <v-radio-group
                                            row
                                            v-model="serviceRequest.receive_email"
                                            hide-details
                                            style="margin-top: 0px;"
                                        >
                                            <v-radio
                                                label="Yes, send email"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="Yes"
                                            />
                                            <v-radio
                                                label="No"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="No"
                                            />
                                        </v-radio-group>
                                    </v-col> -->
                                    <v-col
                                        class="text-right"
                                        cols="12"
                                    >
                                        <v-btn
                                            text
                                            class="no-uppercase rounded-lg os-13-sb mr-4"
                                            color="#2E567A"
                                            @click="$router.push('/service/search')"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            class="white--text no-uppercase rounded-lg os-13-sb"
                                            color="#FFA858"
                                            @click="confirmRequestService"
                                            :disabled="cannotRequestService"
                                        >
                                            Request
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- E-mail Notifications -->
                        </v-row>
                    </v-form>
                </v-col>
                <!-- Request -->

                <!-- Service Details -->
                <v-col
                    cols="6"
                    style="max-height: calc(100vh - 174px); overflow-y: auto;"
                >
                    <v-card
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                style="position: relative;"
                            >
                                <v-btn
                                    icon
                                    class="mr-2 pr-4"
                                    style="position: absolute;"
                                    v-if="!serviceToRequestDetail.is_favourite"
                                    @click="favouriteProfessionalService"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        class="ml-4"
                                    >
                                        {{ mdiHeartOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    class="mr-2 pr-4"
                                    style="position: absolute;"
                                    v-else
                                    @click="favouriteProfessionalService"
                                >
                                    <v-icon
                                        color="#E2574C"
                                        class="ml-4"
                                    >
                                        {{ mdiHeart }}
                                    </v-icon>
                                </v-btn>
                                <div
                                    style="display: flex; justify-content: center;"
                                >
                                    <v-avatar
                                        size="64"
                                    >
                                        <v-img
                                            :src="serviceToRequestDetail.image ? serviceToRequestDetail.image : serviceToRequestDetail.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                            contain
                                        />
                                    </v-avatar>
                                </div>
                            </v-col>
                            <v-col
                                class="os-16-sb text-truncate text-center py-0"
                                cols="12"
                            >
                                <div @click="goToProfile()" class="text-truncate" style="cursor: pointer; text-decoration: underline;">
                                    <span v-if="serviceToRequestDetail.owner.company_id">{{ serviceToRequestDetail.owner.company_name }}</span>
                                    <span v-else>{{ serviceToRequestDetail.owner.first_name }} {{ serviceToRequestDetail.owner.last_name }}</span>
                                </div>
                            </v-col>
                            <v-col
                                class="os-12-sb text-truncate text-center pt-0"
                                cols="12"
                                style="color: #2E567A; text-decoration: underline;"
                            >
                                {{ serviceToRequestDetail.owner.email }}
                            </v-col>
                            <v-col
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                class="os-16-sb text-truncate text-center pb-0"
                                cols="12"
                            >
                                {{ serviceToRequestDetail.name }}
                            </v-col>
                            <v-col
                                class="os-12-r text-center mb-1"
                                cols="12"
                                style="color: #8F9BB3;"
                                v-html="serviceToRequestDetail.description"
                            >
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <div
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Industry:
                                </div>
                                <div
                                    class="os-12-sb"
                                    style="color: #2E567A"
                                >
                                    <v-row>
                                        <v-col
                                            cols="6"
                                            class="text-truncate"
                                        >
                                            {{ serviceToRequestDetail.industry.name }}
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            class="text-right text-truncate pl-0"
                                            style="margin-top: -2px;"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                            >
                                                {{ mdiMapMarkerOutline }}
                                            </v-icon>
                                            <span>{{ serviceToRequestDetail.work_location.name }}</span>
                                        </v-col>
                                    </v-row>
                                </div>
                                <!-- <div
                                    class="os-10-r text-truncate"
                                    style="color: #8F9BB3;"
                                >
                                    Title:
                                </div> -->
                                <div
                                    class="os-12-sb"
                                    style="color: #2E567A"
                                >
                                    <v-row>
                                        <!-- <v-col
                                            cols="8"
                                            class="text-truncate"
                                        >
                                            {{ serviceToRequestDetail.jobTitle.name }}
                                        </v-col> -->
                                        <v-col
                                            cols="12"
                                            class="text-right"
                                            v-if="serviceToRequestDetail.owner.rating"
                                        >
                                            <span>{{ serviceToRequestDetail.owner.rating }}</span>
                                            <v-icon
                                                color="#FFA858"
                                                class="mt-n1"
                                            >
                                                {{ mdiStarOutline }}
                                            </v-icon>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="text-right os-10-r"
                                            v-else
                                            style="color: #8F9BB3;"
                                        >
                                            <span>No rating</span>
                                            <v-icon
                                                color="#8F9BB3"
                                                class="mt-n1"
                                            >
                                                {{ mdiStarOutline }}
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-12-b text-truncate"
                                style="color: #2E567A;"
                            >
                                <span v-if="serviceToRequestDetail.interval.id < 4">{{ serviceToRequestDetail.currency.symbol }}{{ serviceToRequestDetail.rate }} per {{ serviceToRequestDetail.interval.name }}</span>
                                <span v-else>Quote Upon Request</span>
                            </v-col>
                        </v-row>
                    </v-card>

                    <div
                        style="max-width: 280px;"
                        class="py-6"
                    >
                        <general-document-component
                            :documents="serviceToRequestDetail.owner.document"
                            :documentCheckboxes="false"
                            :canDeleteDocuments="false"
                            :canEditDocuments="false"
                            :fileType="'other'"
                            @deleteDocument="confirmDeleteDocument"
                            @downloadDocument="downloadOwnerDocument"
                            @createDocument="createDocument"
                        />
                    </div>
                </v-col>
                <!-- Service Details -->
            </v-row>
        </v-col>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-col
            cols="12"
            v-else-if="$vuetify.breakpoint.smAndDown"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
        >
            <v-row>
                <!-- Heading & Back Button -->
                <v-col
                    cols="12"
                    class="os-22-sb text-center pt-6 pb-9"
                    style="position: relative;"
                >
                    <v-btn
                        icon
                        @click="$router.push('/service/search')"
                        style="position: absolute; left: 12px;"
                        class="mb-1"
                    >
                        <v-icon
                            size="24"
                            color="#2E567A"
                            style="opacity: 0.65;"
                        >
                            {{ mdiArrowLeftThinCircleOutline }}
                        </v-icon>
                    </v-btn>
                    <div class="text-truncate" style="position: absolute; left: 48px; max-width: calc(100vw - 60px);">{{ serviceToRequestDetail.name }}</div>
                </v-col>
                <!-- Heading & Back Button -->

                <!-- Service Details -->
                <v-col
                    cols="12"
                >
                    <v-card
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                style="position: relative;"
                            >
                                <v-btn
                                    icon
                                    class="mr-2 pr-4"
                                    style="position: absolute;"
                                    v-if="!serviceToRequestDetail.is_favourite"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        class="ml-4"
                                    >
                                        {{ mdiHeartOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    class="mr-2 pr-4"
                                    style="position: absolute;"
                                    v-else
                                >
                                    <v-icon
                                        color="#E2574C"
                                        class="ml-4"
                                    >
                                        {{ mdiHeart }}
                                    </v-icon>
                                </v-btn>
                                <div
                                    style="display: flex; justify-content: center;"
                                >
                                    <v-avatar
                                        size="64"
                                    >
                                        <v-img
                                            :src="serviceToRequestDetail.image ? serviceToRequestDetail.image : serviceToRequestDetail.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                            contain
                                        />
                                    </v-avatar>
                                </div>
                            </v-col>
                            <v-col
                                class="os-16-sb text-truncate text-center py-0"
                                cols="12"
                            >
                                <div @click="goToProfile()" style="cursor: pointer; text-decoration: underline;" class="text-truncate">
                                    <span v-if="serviceToRequestDetail.owner.company_id">{{ serviceToRequestDetail.owner.company_name }}</span>
                                    <span v-else>{{ serviceToRequestDetail.owner.first_name }} {{ serviceToRequestDetail.owner.last_name }}</span>
                                </div>
                            </v-col>
                            <v-col
                                class="os-12-sb text-truncate text-center pt-0"
                                cols="12"
                                style="color: #2E567A; text-decoration: underline;"
                            >
                                {{ serviceToRequestDetail.owner.email }}
                            </v-col>
                            <v-col
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                class="os-16-sb text-truncate text-center pb-0"
                                cols="12"
                            >
                                {{ serviceToRequestDetail.name }}
                            </v-col>
                            <v-col
                                class="os-12-r text-center mb-1"
                                cols="12"
                                style="color: #8F9BB3;"
                                v-html="serviceToRequestDetail.description"
                            >
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <div
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Industry:
                                </div>
                                <div
                                    class="os-12-sb"
                                    style="color: #2E567A"
                                >
                                    <v-row>
                                        <v-col
                                            cols="6"
                                            class="text-truncate"
                                        >
                                            {{ serviceToRequestDetail.industry.name }}
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            class="text-right text-truncate pl-0"
                                            style="margin-top: -2px;"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                            >
                                                {{ mdiMapMarkerOutline }}
                                            </v-icon>
                                            <span>{{ serviceToRequestDetail.work_location.name }}</span>
                                        </v-col>
                                    </v-row>
                                </div>
                                <!-- <div
                                    class="os-10-r text-truncate"
                                    style="color: #8F9BB3;"
                                >
                                    Title:
                                </div> -->
                                <div
                                    class="os-12-sb"
                                    style="color: #2E567A"
                                >
                                    <v-row>
                                        <!-- <v-col
                                            cols="8"
                                            class="text-truncate"
                                        >
                                            {{ serviceToRequestDetail.jobTitle.name }}
                                        </v-col> -->
                                        <v-col
                                            cols="12"
                                            class="text-right"
                                            v-if="serviceToRequestDetail.owner.rating"
                                        >
                                            <span>{{ serviceToRequestDetail.owner.rating }}</span>
                                            <v-icon
                                                color="#FFA858"
                                                class="mt-n1"
                                            >
                                                {{ mdiStarOutline }}
                                            </v-icon>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="text-right os-10-r"
                                            v-else
                                            style="color: #8F9BB3;"
                                        >
                                            <span>No rating</span>
                                            <v-icon
                                                color="#8F9BB3"
                                                class="mt-n1"
                                            >
                                                {{ mdiStarOutline }}
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-12-b text-truncate"
                                style="color: #2E567A;"
                            >
                                <span v-if="serviceToRequestDetail.interval.id < 4">{{ serviceToRequestDetail.currency.symbol }}{{ serviceToRequestDetail.rate }} per {{ serviceToRequestDetail.interval.name }}</span>
                                <span v-else>Quote Upon Request</span>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- <div
                        style="max-width: 280px;"
                        class="py-6"
                    > -->
                    <general-document-component
                        :documents="serviceToRequestDetail.owner.document"
                        :documentCheckboxes="false"
                        :canDeleteDocuments="false"
                        :canEditDocuments="false"
                        :fileType="'other'"
                        @deleteDocument="confirmDeleteDocument"
                        @downloadDocument="downloadOwnerDocument"
                        @createDocument="createDocument"
                        class="pt-6"
                    />
                    <!-- </div> -->
                </v-col>
                <!-- Service Details -->

                <!-- Request -->
                <v-col
                    cols="12"
                >
                    <v-form
                        v-model="validServiceRequestForm"
                        ref="serviceRequestForm"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="os-15-sb pb-0"
                            >
                                Service Requirements
                            </v-col>

                            <!-- Requirements -->
                            <v-col>
                                <general-tip-tap-text-editor-component
                                    class="pb-3"
                                    :placeholder="'Provide some information about the service that you require. Be as descriptive as possible.'"
                                    @textChanged="descriptionChanged"
                                    :text="serviceRequest.requirements"
                                />
                            </v-col>
                            <!-- Requirements -->

                            <!-- Documents -->
                            <v-col
                                cols="12"
                            >
                                <general-document-component
                                    :documents="serviceRequestDocuments"
                                    :documentCheckboxes="false"
                                    :canDeleteDocuments="true"
                                    :canDownloadDocuments="false"
                                    :canEditDocuments="true"
                                    :fileType="'files'"
                                    @deleteDocument="confirmDeleteDocument"
                                    @createDocument="createDocument"
                                />
                            </v-col>
                            <!-- Documents -->

                            <!-- Date Requirements -->
                            <v-col
                                class="os-15-sb pb-0"
                                cols="12"
                            >
                                Do you require this by a specific date?
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-row
                                    align="center"
                                >
                                    <v-col
                                        cols="12"
                                        sm="8"
                                    >
                                        <v-radio-group
                                            row
                                            v-model="serviceRequest.is_specific_date"
                                            hide-details
                                            style="margin-top: 0px;"
                                            @change="specificDateChanged"
                                        >
                                            <v-radio
                                                label="Yes"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="Yes"
                                            />
                                            <v-radio
                                                label="No"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="No"
                                            />
                                            <v-radio
                                                label="Unknown"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="Unknown"
                                            />
                                        </v-radio-group>
                                    </v-col>
                                    <v-col
                                        class="py-0"
                                        cols="12"
                                        sm="4"
                                    >
                                        <v-menu
                                            v-model="dateMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    :rules="dateRules"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-model="serviceRequest.start_date"
                                                    :disabled="serviceRequest.is_specific_date !== 'Yes'"
                                                />
                                            </template>
                                            <v-date-picker
                                                no-title
                                                scrollable
                                                color="#2E567A"
                                                @input="dateMenu = false"
                                                v-model="serviceRequest.start_date"
                                            />
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- Date Requirements -->

                            <!-- E-mail Notifications -->
                            <!-- <v-col
                                cols="12"
                                class="os-12-r"
                                style="color: #8F9BB3;"
                            >
                                Your request will be sent to the fixer and they will be notified of via the messenger. Do you want to be <span class="os-12-sb">notified via email</span> when you receive a reply?
                            </v-col> -->
                            <v-col
                                cols="12"
                            >
                                <v-row
                                    align="center"
                                >
                                    <!-- <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-radio-group
                                            row
                                            v-model="serviceRequest.receive_email"
                                            hide-details
                                            style="margin-top: 0px;"
                                        >
                                            <v-radio
                                                label="Yes"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="Yes"
                                            />
                                            <v-radio
                                                label="No"
                                                color="#2E567A"
                                                class="blue--text--xxs"
                                                :ripple="false"
                                                value="No"
                                            />
                                        </v-radio-group>
                                    </v-col> -->
                                    <v-col
                                        class="text-right"
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-btn
                                            text
                                            class="no-uppercase rounded-lg os-13-sb mr-4"
                                            color="#2E567A"
                                            @click="$router.push('/service/search')"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            class="white--text no-uppercase rounded-lg os-13-sb"
                                            color="#FFA858"
                                            @click="confirmRequestService"
                                            :disabled="cannotRequestService"
                                        >
                                            Request
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- E-mail Notifications -->
                        </v-row>
                    </v-form>
                </v-col>
                <!-- Request -->
            </v-row>
        </v-col>
        <!-- Mobile -->

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Document'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->

        <!-- Payment Overlay -->
        <payment-overlay-component
            :showPaymentOverlayBoolean="showPaymentOverlayBoolean"
            :invoiceData="invoiceData"
            @showPaymentOverlay="showPaymentOverlay"
        />
        <!-- Payment Overlay -->

        <!-- Confirm Request Overlay -->
        <work-professional-service-request-confirm-request-overlay-component
            :showRequestOverlayBoolean="showRequestOverlayBoolean"
            :serviceToRequest="serviceToRequestDetail"
            @toggleRequestOverlayComponent="toggleRequestOverlayComponent"
        />
        <!-- Confirm Request Overlay -->
    </v-row>
</template>
<script>
    import GeneralTipTapTextEditorComponent from '@/components/general/tiptap/GeneralTipTapTextEditorComponent.vue'
    import GeneralDocumentComponent from '@/components/general/document/GeneralDocumentComponent.vue'
    import { mdiArrowLeftThinCircleOutline, mdiMapMarkerOutline, mdiStarOutline, mdiHeart, mdiHeartOutline } from '@mdi/js'
    import { WorkProfessionalServiceRequestDocumentController, WorkProfessionalServiceFavouriteController, WorkProfessionalServiceRequestController, WorkProfessionalServiceDocumentController, GeneralInvoiceController, WorkProfessionalServiceDetailController } from '@/logic/controller/index.controller.js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'
    import PaymentOverlayComponent from '@/overlays/payment/PaymentOverlayComponent.vue'
    import WorkProfessionalServiceRequestConfirmRequestOverlayComponent from '@/overlays/work/professionalService/request/WorkProfessionalServiceRequestConfirmRequestOverlayComponent.vue'

    export default {
        name: 'WorkProfessionalServiceActionGeneralRequestPage',

        components: {
            GeneralTipTapTextEditorComponent,
            GeneralDocumentComponent,
            GeneralConfirmDeleteOverlayComponent,
            PaymentOverlayComponent,
            WorkProfessionalServiceRequestConfirmRequestOverlayComponent
        },

        watch: {
            '$route.params.id': {
                async handler (value) {
                    await this.getProfessionalServiceDetail()
                }
            }
        },

        computed: {
            dateRules () {
                let today = new Date()
                today = today.toISOString().split('T')[0]

                if (this.serviceRequest.is_specific_date === 'Yes') {
                    if (this.serviceRequest.start_date === null) return [ 'Date required' ]
                    else if (this.serviceRequest.start_date.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                    else if (new Date(this.serviceRequest.start_date.replace(/-/g, '/')).getTime() < new Date(today.replace(/-/g, '/')).getTime()) return [ 'Can\'t be before today' ]
                    else return []
                } else return []
            },

            cannotRequestService () {
                if (this.validServiceRequestForm && this.validDescription) return false
                else return true
            }
        },

        data () {
            return {
                mdiArrowLeftThinCircleOutline,
                mdiMapMarkerOutline,
                mdiStarOutline,
                mdiHeart,
                mdiHeartOutline,
                serviceToRequestDetail: {
                    currency: {},
                    interval: {},
                    industry: {},
                    owner: {},
                    work_location: {}
                },
                serviceRequest: {
                    start_date: null,
                    is_specific_date: 'Yes',
                    receive_email: 'No'
                },
                workProfessionalServiceRequestDocumentController: null,
                workProfessionalServiceRequestController: null,
                workProfessionalServiceDetailController: null,
                workProfessionalServiceDocumentController: null,
                workProfessionalServiceFavouriteController: null,
                generalInvoiceController: null,
                serviceRequestDocuments: [],
                showDeleteConfirmationOverlayBoolean: false,
                documentToDelete: null,
                documentToDeleteIndex: null,
                invoiceData: {
                    item: {},
                    invoice: {}
                },
                showPaymentOverlayBoolean: false,
                dateMenu: false,
                showRequestOverlayBoolean: false,
                validServiceRequestForm: false,
                validDescription: false
            }
        },

        beforeMount () {
            this.workProfessionalServiceRequestDocumentController = new WorkProfessionalServiceRequestDocumentController()
            this.workProfessionalServiceRequestController = new WorkProfessionalServiceRequestController()
            this.workProfessionalServiceDetailController = new WorkProfessionalServiceDetailController()
            this.workProfessionalServiceDocumentController = new WorkProfessionalServiceDocumentController()
            this.workProfessionalServiceFavouriteController = new WorkProfessionalServiceFavouriteController()
            this.generalInvoiceController = new GeneralInvoiceController()
        },

        async mounted () {
            await this.getProfessionalServiceDetail()
        },

        methods: {
            async getProfessionalServiceDetail () {
                try {
                    this.serviceToRequestDetail = await this.workProfessionalServiceDetailController.retrieve(this.$route.params.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async favouriteProfessionalService () {
                try {
                    await this.workProfessionalServiceFavouriteController.toggleFavourite(this.serviceToRequestDetail.id)

                    if (this.serviceToRequestDetail.is_favourite) {
                        this.serviceToRequestDetail.is_favourite = 0

                        this.$store.commit('setSnackbar', {
                            text: 'Removed from favourites',
                            show: true,
                            color: '#069B34'
                        })
                    } else {
                        this.serviceToRequestDetail.is_favourite = 1

                        this.$store.commit('setSnackbar', {
                            text: 'Added to favourites',
                            show: true,
                            color: '#069B34'
                        })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            goToProfile () {
                if (this.serviceToRequestDetail.owner.role_id === 1) this.$router.push(`/profile/individual/${this.serviceToRequestDetail.owner.id}`)
                else if (this.serviceToRequestDetail.owner.role_id === 2) this.$router.push(`/profile/company/${this.serviceToRequestDetail.owner.company_id}`)
            },

            confirmRequestService () {
                this.toggleRequestOverlayComponent(true)
            },

            toggleRequestOverlayComponent (value, confirmRequest) {
                this.showRequestOverlayBoolean = value

                if (confirmRequest) this.requestService(this.item)
            },

            async requestService () {
                try {
                    if (this.serviceRequest.is_specific_date === 'Yes') this.serviceRequest.is_specific_date = 1
                    else if (this.serviceRequest.is_specific_date === 'No') this.serviceRequest.is_specific_date = 0
                    else if (this.serviceRequest.is_specific_date === 'Unknown') this.serviceRequest.is_specific_date = null

                    if (this.serviceRequest.receive_email === 'Yes') this.serviceRequest.receive_email = 1
                    else if (this.serviceRequest.receive_email === 'No') this.serviceRequest.receive_email = 0

                    const response = await this.workProfessionalServiceRequestController.upsert(this.$route.params.id, this.serviceRequest)

                    if (this.serviceRequestDocuments.length > 0) {
                        const documentsForm = new FormData()
                        for (let index = 0; index < this.serviceRequestDocuments.length; index++) {
                            const document = this.serviceRequestDocuments[index].file
                            documentsForm.append('document', document)
                        }

                        await this.workProfessionalServiceRequestDocumentController.create(this.$route.params.id, response.id, documentsForm)
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Request sent successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.$router.push('/service/requested/pending')
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showPaymentOverlay (value, confirmApply, selectedCard) {
                this.showPaymentOverlayBoolean = value

                if (confirmApply) this.payInvoice(this.invoiceData, selectedCard)
            },

            async payInvoice (invoice, card) {
                try {
                    await this.generalInvoiceController.pay(invoice.invoice.id, card.card_id)
                    await this.applyForJob(this.serviceRequest)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            specificDateChanged () {
                if (this.serviceRequest.is_specific_date !== 'Yes' && this.serviceRequest.is_specific_date !== 1) this.serviceRequest.start_date = null
            },

            descriptionChanged (text) {
                this.serviceRequest.requirements = text
                if (this.serviceRequest.requirements === '<p></p>' || this.serviceRequest.requirements === null) {
                    this.serviceRequest.requirements = null
                    this.validDescription = false
                } else this.validDescription = true
            },

            async createDocument (document, uploadedFile) {
                try {
                    let sizeInMB = uploadedFile.size / (1024 * 1024)
                    sizeInMB = (Math.ceil(sizeInMB * 10) / 10).toFixed(1)
                    this.serviceRequestDocuments.push({ file: uploadedFile, filename: uploadedFile.name, sizeInMB })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async confirmDeleteDocument (document, documentIndex) {
                this.documentToDelete = document
                this.documentToDeleteIndex = documentIndex
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteDocument (document, documentToDeleteIndex) {
                try {
                    this.serviceRequestDocuments.splice(documentToDeleteIndex, 1)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async downloadOwnerDocument (documentToDownload) {
                try {
                    const downloadResponse = await this.workProfessionalServiceDocumentController.download(this.$route.params.id, encodeURIComponent(documentToDownload.fullpath))

                    var data = new Blob([downloadResponse])
                    if (typeof window.navigator.msSaveBlob === 'function') {
                        window.navigator.msSaveBlob(data, documentToDownload.filename)
                    } else {
                        var blob = data
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = documentToDownload.filename

                        document.body.appendChild(link)

                        link.click()
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Downloaded successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteDocument(this.documentToDelete, this.documentToDeleteIndex)
            }
        }
    }
</script>
<style scoped>
    .blue--text--xxs /deep/ label {
        color: #2E567A;
        font-size: 12px;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }

    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }
</style>
